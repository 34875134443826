import React, { useMemo } from 'react';
import { ProductCard, SkeletonLoading } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';
import useStyles from './styles';
import MetaTags from './MetaTags';
import { Pagination } from '@mui/material';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

const NUMBER_OF_RESULTS = 20;

function ListProducts({ products, loading }) {
  const classes = useStyles();
  useScrollToTop();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const pageId = searchParams.get('page_id') ?? 1;

  const currentList = useMemo(() => {
    const end = Number(pageId) * NUMBER_OF_RESULTS;
    const start = end - NUMBER_OF_RESULTS;
    return products.slice(start, end);
  }, [products, pageId]);

  const handleChangePage = (_, page) => {
    const pathname = location.pathname;
    const pathUrl = `${pathname}?page_id=${page}`;
    navigate(pathUrl);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (loading) {
    return <SkeletonLoading noPadding isDouble />;
  }

  if (!products || products?.length === 0) {
    return <div className={classes.container}>Chưa có sản phẩm!!!</div>;
  }

  const totalPage = Math.floor(products.length / NUMBER_OF_RESULTS) + 1;

  return (
    <div className={classes.container}>
      <MetaTags />
      <div className={classes.listItem}>
        {currentList &&
          currentList.map((item) => <ProductCard key={item.id} {...item} />)}
      </div>
      <div className={classes.paginationWrapper}>
        <Pagination
          count={totalPage}
          color="primary"
          onChange={handleChangePage}
          page={Number(pageId)}
        />
      </div>
    </div>
  );
}

export default ListProducts;
