import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#e9387e',
    },
    white: {
      main: '#ffffff'
    }
  },
});

export default theme;
