import React, { useEffect, useState, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Menu, Footer } from 'components';
import { useLazyQuery, gql } from '@apollo/client';
import theme from './theme';
import './scss/index.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import AppRoutes from 'AppRoutes';

const PRODUCTS_QUERY = gql`
  query Products($skip: Int) {
    products(first: 100, skip: $skip, orderBy: publishedAt_DESC) {
      id
      name
      price
      comboPrice
      comboNumber
      unit
      unitNote
      group
      isSaleOff
      mainImage {
        url
      }
    }
  }
`;

export const AppContext = React.createContext({
  products: null,
});

function App() {
  const skipNumber = useRef(null);
  // Flag to check should add to product list
  const flag = useRef(null);
  const [getProducts, { data }] = useLazyQuery(PRODUCTS_QUERY);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!skipNumber.current && products.length === 0) {
      setLoading(true);
      getProducts({
        variables: { skip: 0 },
      });
      flag.current = true;
      skipNumber.current = 100;
    }
    if (flag.current && data?.products.length) {
      setLoading(false);
      const newProducts = [...products, ...data.products];
      setProducts(newProducts);
      flag.current = false;
    }
    if (Boolean(skipNumber.current) && data?.products.length === 100) {
      getProducts({
        variables: {
          skip: skipNumber.current,
        },
      });
      skipNumber.current = skipNumber.current + 100;
      flag.current = true;
    }
  }, [data?.products, getProducts, products]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          products,
        }}
      >
        <Container
          maxWidth="xl"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          })}
        >
          <Menu />
          <AppRoutes productList={products} loading={loading} />
          <Footer />
        </Container>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
