// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  --mary--main-color: #e9387e;
  --mary--black-85: rgba(2, 9, 18, 0.75);
}

.MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  color: var(--mary--black-85);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/scss/color.scss","webpack://./src/scss/index.scss","webpack://./src/scss/custom_mui.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,sCAAA;ACCF;;ACHA;EACE,0BAAA;EACA,2BAAA;ADMF;;AAJA;EACE,SAAA;EACA,UAAA;EACA,uBAAA;EACA,4BAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;AAKF;;AAFA;EACI,+EAAA;AAKJ;;AADA;EACE,SAAA;AAIF;;AADA;EACE,uBAAA;AAIF","sourcesContent":["body {\n  --mary--main-color: #{$main-color};\n  --mary--black-85: #{$black-85};\n}","@import \"variables\";\n@import \"color\";\n@import \"custom_mui\";\n\nbody {\n  margin: 0;\n  padding: 0;\n  background-color: white;\n  color: var(--mary--black-85);\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n      monospace;\n  }\n\np {\n  margin: 0;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}",".MuiContainer-root {\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
